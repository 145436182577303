body { 
  margin: 0 
}

.code-box-demo {
  text-align: center;
}
.ant-layout-header,
.ant-layout-footer {
  color: #7dbcea;
  background: #fff;
}
[data-theme="dark"] .ant-layout-header {
  background: #fff;
}
[data-theme="dark"] .ant-layout-footer {
  background: #fff;
}
.ant-layout-footer {
  line-height: 1.5;
}
.ant-layout-sider {
  color: #3ba0e9;
  background: #fff;
  line-height: 120px;
}
[data-theme="dark"] .ant-layout-sider {
  background: #fff;
}
.ant-layout-content {
  color: #fff;
  min-height: 120px;
  line-height: 120px;
}
[data-theme="dark"] .ant-layout-content {
  background: #fff;
}
.code-box-demo > .ant-layout + .ant-layout {
  margin-top: 48px;
}
.pic {
  padding-top: 150px;
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 20%;
  height: 20%;
}
.inputNameSize {
  width: 380px;
}.flex-parent {
  display: flex;  
}
.elem {
  padding: 5px 0px 0px 5px;
  width: 50%;
}
.radiobtn {
  display: flex;
  justify-content: center;
}
.typelabel {
  padding: 5px 5px 0px 0px;
}
.labelName {
  padding: 0px 0px 0px 31px;
}

.container {
  display: inline-flex;
 }

 .vesselName {
  padding-left: 700px;
  color:blue
  }

  .label {
    padding-left: 16px;
  }

  .container__title {
    display: flex;
    margin-top: 20px;
    grid-gap:10px
  }
/* .ant-table-cell{
  background-color: rgb(201, 205, 206);
} */